<template>
  <div class="w-100">
    <div class="app-bold-font d-flex align-start font-20">
      Password Settings
    </div>
    <v-card class="mt-5">
      <v-card-text class="pt-5 pb-0">
        <v-text-field
          v-model="oldPassword"
          outlined
          dense
          label="Old password"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @input="onChange"
        />
        <v-text-field
          v-model="newPassword"
          outlined
          dense
          label="New password"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @input="onChange"
        />
        <v-text-field
          v-model="confirmPassword"
          outlined
          dense
          label="Confirm password"
          :error-messages="errorMessage"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @input="onChange"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end mr-5 pb-5">
        <v-btn color="primary" @click="onSavePassword" :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: null,
      loading: false,
      showPassword: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.newPassword != this.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      snackbar: false,
      snackMessage: null,
    };
  },
  methods: {
    ...mapActions("usersetting", {
      changePassword: "changePassword",
    }),
    onSavePassword() {
      if (!this.oldPassword || !this.newPassword || !this.confirmPassword) {
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        this.errorMessage = "Password does not match";
        console.log(this.errorMessage);
        return;
      }
      this.loading = true;
      this.changePassword({
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      })
        .then(() => {
          this.newPassword = "********";
          this.confirmPassword = "********";
          this.oldPassword = "********";
          this.showSnackbar("Password changed successfully.");
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
            this.loading = false;
          } else {
            this.errorMessage = error;
            this.loading = false;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    showSnackbar(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },
  },
};
</script>
