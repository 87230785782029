<template>
  <div class="w-100">
    <div class="app-bold-font d-flex align-start font-20">
      {{ $t("account_settings") }}
    </div>
    <div class="d-flex flex-column mt-5">
      <v-card class="w-100">
        <v-card-title>
          {{ $t("account_information") }}
        </v-card-title>
        <v-card-text class="pb-0">
          <div class="d-flex align-center">
            <div
              class="mr-2 mb-1 dark-font-color app-medium-font"
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              {{ $vuetify.breakpoint.xs ? "Email" : "Email address" }}
            </div>
            <v-tooltip bottom v-if="profile.email_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="#6038f7" size="14">
                  mdi-check-decagram
                </v-icon>
              </template>
              <span>Verified email</span>
            </v-tooltip>
            <div
              class="mb-1 ml-5"
              v-else
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              Not verified<v-btn
                text
                :small="$vuetify.breakpoint.smAndUp"
                :x-small="$vuetify.breakpoint.xs"
                class="ml-2"
                @click="verifyEmail"
                :disabled="disableResendEmail"
                :loading="sendingEmail"
                >{{
                  resendEmailCount
                    ? "Wait " +
                      resendEmailCount +
                      "s to resend verification email"
                    : "Verify Now"
                }}</v-btn
              >
            </div>
          </div>
          <v-text-field
            v-model="email"
            outlined
            dense
            placeholder="Email"
            @input="onChange"
            :rules="[rules.required, rules.email]"
          />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="d-flex align-center">
            <div
              class="mr-2 mb-1 dark-font-color app-medium-font"
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              {{ $vuetify.breakpoint.xs ? "Phone" : "Phone number" }}
            </div>
            <v-tooltip bottom v-if="profile.phone_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="#6038f7" size="14">
                  mdi-check-decagram
                </v-icon>
              </template>
              <span>Verified phone number</span>
            </v-tooltip>
            <div
              class="mb-1 ml-5"
              v-else
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              Not verified
              <v-btn
                text
                :small="$vuetify.breakpoint.smAndUp"
                :x-small="$vuetify.breakpoint.xs"
                class="ml-2"
                :disabled="disableResendSMS"
                @click="verifyPhone"
                :loading="sendingCode"
                >Verify Now</v-btn
              >
            </div>
          </div>
          <AppPhoneNumberInput
            v-model="phone"
            @input="onChange"
            @update="onUpdatePhone"
          />
        </v-card-text>
        <v-card-text v-if="errorMessage">
          <v-alert
            dense
            type="error"
            v-text="errorMessage"
            v-if="errorMessage"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-5 mb-3">
          <v-btn color="primary" @click="onSave" :loading="loading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="w-100 mt-5">
        <v-card-title>
          {{ $t("delete_account") }}
        </v-card-title>
        <v-card-text class="pb-0">
          This is permanent. When you delete your AKINDr account, you won't be
          able to retrieve the content or information you've shared on AKINDr.
        </v-card-text>
        <v-card-text v-if="confirmDelete">
          Please type <span class="app-bold-font">DELETE</span> to confirm your
          deletion.
          <v-text-field
            v-model="confirmDeleteText"
            outlined
            dense
            placeholder="DELETE"
            @input="onChange"
            class="mt-1"
            :error-messages="errorDelete"
          />
        </v-card-text>
        <v-card-text v-if="errorDeleteMessage">
          <v-alert
            dense
            type="error"
            v-text="errorDeleteMessage"
            v-if="errorDeleteMessage"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-5 mb-3 mt-5">
          <v-btn
            color="red"
            @click="onDeleteAccount"
            :loading="deletingAccount"
            dark
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
      <phone-verification-dialog
        :verifyDialog="verifyPhoneDialog"
        :onClose="onCloseVerify"
        v-if="verifyPhoneDialog"
      />
    </div>
  </div>
</template>
<script>
import AppPhoneNumberInput from "@/components/common/AppPhoneNumberInput.vue";
import PhoneVerificationDialog from "@/components/common/PhoneVerificationDialog.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AppPhoneNumberInput,
    PhoneVerificationDialog,
  },
  data() {
    return {
      email: "",
      phone: "",
      phonePayload: {},
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      loading: false,
      sendingEmail: false,
      snackbar: false,
      snackMessage: null,
      errorMessage: null,
      resendEmailCount: 0,
      disableResendEmail: false,
      sendingCode: false,
      disableResendSMS: false,
      verifyPhoneDialog: false,
      confirmDelete: false,
      deletingAccount: false,
      confirmDeleteText: "",
      errorDelete: null,
      errorDeleteMessage: null,
    };
  },
  methods: {
    ...mapActions({
      updateProfile: "auth/updateProfile",
      sendEmailVerification: "auth/sendEmailVerification",
      sendVerificationCode: "auth/sendVerificationCode",
      deleteAccount: "auth/deleteAccount",
    }),
    onChange() {
      this.errorMessage = null;
      this.errorDelete = null;
      this.errorDeleteMessage = null;
    },
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
    onSave() {
      this.errorMessage = null;
      if (this.email || this.phone) {
        this.loading = true;
        let phone = this.phone;
        if (
          this.phonePayload.isValid &&
          this.phonePayload.countryCallingCode != "1"
        ) {
          phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
        }
        this.updateProfile({ email: this.email, phone })
          .then(() => {
            this.loading = false;
            this.email = this.profile.email;
            this.phone = this.profile.phone;
            let str = "";
            if (!this.profile.email_verified) str = " Please verify your email";
            if (!this.profile.phone_verified)
              str = str
                ? str + " and phone number"
                : "Please verify your phone number";
            this.showSnackMessage(
              "Updated info successfully!" + (str ? str + "." : "")
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
            this.loading = false;
          });
      }
    },
    verifyEmail() {
      if (this.email != this.profile.email) {
        this.errorMessage = "Please save the updated email first.";
        return;
      }
      this.sendingEmail = true;
      this.sendEmailVerification()
        .then(() => {
          this.sendingEmail = false;
          this.disableResendEmail = true;
          this.resendEmailCount = 60;
          this.countDownEmail();
        })
        .catch((error) => {
          console.log(error);
          this.sendingEmail = false;
        });
    },
    verifyPhone() {
      if (this.phone != this.profile.phone) {
        this.errorMessage = "Please save the updated phone number first.";
        return;
      }
      this.sendingCode = true;
      this.sendVerificationCode()
        .then(() => {
          this.sendingCode = false;
          this.verifyPhoneDialog = true;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    showSnackMessage(message) {
      this.snackbar = true;
      this.snackMessage = message;
    },
    countDownEmail() {
      if (this.resendEmailCount > 0) {
        setTimeout(() => {
          this.resendEmailCount -= 1;
          this.countDownEmail();
        }, 1000);
      } else {
        this.disableResendEmail = false;
      }
    },
    onCloseVerify() {
      this.verifyPhoneDialog = false;
    },
    onDeleteAccount() {
      if (!this.confirmDelete) {
        this.confirmDelete = true;
        this.confirmDeleteText = "";
      } else {
        if (this.confirmDeleteText == "DELETE") {
          this.deletingAccount = true;
          this.deleteAccount()
            .then(() => {
              this.deletingAccount = false;
              this.$router.push({ name: "login" });
            })
            .catch((error) => {
              this.deletingAccount = false;
              this.errorDeleteMessage = error.response.data.message;
              this.confirmDeleteText = "";
              console.log(error);
            });
        } else if (this.confirmDeleteText == "") {
          this.errorDelete = "Required";
        } else {
          this.errorDelete = "Type correctly";
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile",
    }),
  },
  watch: {
    profile(newValue) {
      this.email = newValue.email;
      this.phone = newValue.phone;
    },
  },
  mounted() {
    this.email = this.profile.email;
    this.phone = this.profile.phone;
  },
};
</script>
