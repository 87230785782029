<template>
  <v-dialog v-model="deleteDialog" persistent max-width="600px">
    <v-card>
      <v-card-text
        class="pb-0 pt-8"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-icon
          size="60"
          color="green"
          class="mb-5"
          v-text="'mdi-alert-circle-outline'"
        />
        <div
          v-text="text"
          :class="$vuetify.breakpoint.xs ? 'text-center font-18' : 'font-20'"
        />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="blue white"
          @click="onClose"
          class="white--text"
          v-text="'Cancel'"
          :small="$vuetify.breakpoint.xs"
        />
        <v-btn
          color="red white"
          @click="onConfirm"
          class="white--text"
          :loading="loading"
          :small="$vuetify.breakpoint.xs"
        >
          Yes, delete it!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    deleteDialog: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Are you sure do you want to delete?",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
    onConfirm: {
      type: Function,
    },
  },
};
</script>
<style scoped></style>
