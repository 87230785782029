<template>
  <div
    class="overflow-visible position-relative"
    style="width: 200px; height: 200px;"
  >
    <div
      v-html="compiledSvg"
      v-if="compiledSvg && !selectedFile"
      style="width: 200px; height: 200px"
    />
    <v-img
      :src="selectedFile ? selectedImage : image ? image : placeholderImage"
      alt="Avatar"
      width="200"
      height="200"
      v-else
    />
    <v-btn
      color="primary"
      class="position-absolute"
      style="top: 10px;right:10px"
      fab
      dark
      x-small
      :loading="isSelectingPhoto"
      @click="onEditButtonClick"
      v-if="editable"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    image: {
      type: String,
    },
    onImageSelected: {
      type: Function,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedFile: null,
      placeholderImage: require("../assets/placeholder.png"),
      isSelectingPhoto: false,
      selectedImage: null,
      compiledSvg: null,
    };
  },
  methods: {
    onEditButtonClick() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        this.selectedImage = URL.createObjectURL(this.selectedFile);
        this.onImageSelected(this.selectedFile);
      }
    },
  },
  mounted() {
    if (this.image && this.image.slice(-4).toLowerCase() == ".svg") {
      console.log(this.image);
      axios({
        method: "get",
        url: this.image,
      })
        .then((result) => {
          this.compiledSvg = result.data;
          console.log(this.compiledSvg);
        })
        .catch((error) => {
          console.error("error getting file", error);
        });
    }
  },
  watch: {
    image(newValue) {
      console.log(newValue);
      if (newValue && newValue.slice(-4).toLowerCase() == ".svg") {
        axios({
          method: "get",
          url: newValue,
        })
          .then((result) => {
            this.compiledSvg = result.data;
          })
          .catch((error) => {
            console.error("error getting file", error);
          });
      }
    },
  },
};
</script>
<style scoped></style>
