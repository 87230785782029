<template>
  <div class="w-100">
    <div class="app-bold-font d-flex align-start font-20 mb-5">
      Notification Settings
    </div>
    <div v-if="loading" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate color="amber" class="mt-10" />
    </div>
    <v-expansion-panels v-else>
      <v-expansion-panel
        class="mb-2 rounded-lg"
        v-for="item in menuList"
        :key="item.key"
      >
        <v-expansion-panel-header>
          <template>
            <div class="app-bold-font font-16">
              {{ item.title }}
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="font-14">
            {{ item.description }}
          </div>
          <v-divider class="my-2" />
          <div>
            <div
              class="font-14 app-bold-font d-flex mt-2 justify-between align-center"
              v-if="item.key != 'messages'"
            >
              <div>
                <v-icon size="16" class="mr-2">mdi-bell</v-icon>
                Push
              </div>
              <v-switch
                hide-details
                class="mt-0 pt-0"
                v-model="item.push"
                @change="onChange(item, 'push')"
              />
            </div>
            <div
              class="font-14 app-bold-font d-flex mt-2 justify-between align-center"
            >
              <div>
                <v-icon size="16" class="mr-2">mdi-email</v-icon>
                Email
              </div>
              <v-switch
                hide-details
                class="mt-0 pt-0"
                v-model="item.email"
                @change="onChange(item, 'email')"
              />
            </div>
            <div
              class="font-14 app-bold-font d-flex mt-2 justify-between align-center"
            >
              <div>
                <v-icon size="16" class="mr-2">mdi-message</v-icon>
                SMS
              </div>
              <v-switch
                hide-details
                class="mt-0 pt-0"
                v-model="item.sms"
                @change="onChange(item, 'sms')"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      menuList: [
        {
          title: "Messages",
          description:
            "These are notifications about the messages you received from other volunteers and agencies",
          key: "messages",
          push: true,
          email: true,
          sms: true,
        },
        {
          title: "Posts",
          description:
            "These are notifications about the new event posts, modified posts",
          key: "posts",
          push: true,
          email: true,
          sms: true,
        },
        {
          title: "Comments",
          description:
            "These are notifications about the comments and reactions on your event post",
          key: "comments",
          push: true,
          email: true,
          sms: true,
        },
        {
          title: "Needs",
          description:
            "These are notifications about the your needs or you applied needs",
          key: "needs",
          push: true,
          email: true,
          sms: true,
        },
        {
          title: "Rewards",
          description:
            "These are notifications about the your rewards or the rewards of your needs",
          key: "rewards",
          push: true,
          email: true,
          sms: true,
        },
        {
          title: "Follows",
          description:
            "These are notifications about your new followers or funs",
          key: "follows",
          push: true,
          email: true,
          sms: true,
        },
        {
          title: "Newsletter, annotations",
          description:
            "These are notifications about the newsletters or annotations",
          key: "newsletters",
          push: true,
          email: true,
          sms: true,
        },
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions("usersetting", {
      getSettings: "getNotificationSettings",
      setSetting: "setNotificationSetting",
    }),
    onChange(item, key) {
      this.setSetting({ key: item.key, subkey: key, value: item[`${key}`] });
    },
  },
  mounted() {
    this.loading = true;
    this.getSettings()
      .then((res) => {
        this.loading = false;
        res.map((r, index) => {
          this.menuList[index] = { ...this.menuList[index], ...r };
        });
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>
