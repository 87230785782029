<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-html="compiledSvg"
          class="cause-icon"
          v-bind="attrs"
          v-on="on"
          :style="
            'fill: ' +
              icon.color +
              ';border-color:' +
              icon.color +
              ';' +
              'width:' +
              size +
              'px; height: ' +
              size +
              'px; border-radius: ' +
              parseInt(size) / 2 +
              'px; padding: ' +
              ($vuetify.breakpoint.xs && size == '30'
                ? '3'
                : parseInt(size) / 5) +
              'px'
          "
        ></div>
      </template>
      <span>{{ name }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  props: {
    name: String,
    type: String,
    size: {
      default: "60",
    },
  },
  data() {
    return {
      compiledSvg: "",
      icon: {},
    };
  },
  watch: {
    name(newValue) {
      this.getIcon({ type: this.type, name: newValue })
        .then((res) => {
          this.icon = res;
          this.loadFile();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  methods: {
    ...mapActions("icon", {
      getIcon: "getIcon",
    }),
    loadFile() {
      axios({
        method: "get",
        url: this.icon.image,
      })
        .then((result) => {
          this.compiledSvg = result.data;
        })
        .catch((error) => {
          console.error("error getting file", error);
        });
    },
  },
  mounted() {
    this.getIcon({ type: this.type, name: this.name })
      .then((res) => {
        this.icon = res;
        this.loadFile();
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style>
.cause-icon {
  border: solid;
  border-width: 1px;
}
</style>
